<template>
  <div class="relative w-full">
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
  async created() {
    this.$auth0.logout()
  }
}
</script>
